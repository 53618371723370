import React, {useEffect, useRef, useState} from 'react';
import { authenticationService } from '../../services/authentication.service';
import IniciarDetenerJornada from "common/src/components/IniciarDetenerJornada";
import CenteredLayout from "../../layouts/CenteredLayout";
import EmpresaService from "common/src/services/EmpresaService";
import Mensaje from "common/src/components/Mensaje/Mensaje";
import {analyticsTrackEvent} from "../../hooks/withGaTracker";

const Home = () => {

    const [currentUser, setCurrentUser] = useState(null);

    const [message, setMessage] = useState(false)

    useEffect(() => {
        setCurrentUser(authenticationService.currentUserValue);

        async function getMessage() {
            const messageRes = await EmpresaService.getMessage(localStorage.access_token)
            setMessage(messageRes)
        }

        getMessage()

    }, []);

    return(<>
        {currentUser &&
            <CenteredLayout title={"Hola " + currentUser.nombre + "!"}>

                <IniciarDetenerJornada token={localStorage.access_token}/>

                <Mensaje message={message} />

            </CenteredLayout>
        }
    </>);
}


export default Home ;
