import React, {useState, useEffect, useMemo} from 'react';
import TiempoListar from "common/src/components/Tiempo/TiempoListar";
import {API_URL, EPS} from "common/src/constants/Constants";
import {ActivityIndicator} from "react-native";
import axios from 'axios';
import {authenticationService} from "../../services/authentication.service";
import {authHeader} from "../../helpers/auth-header";
import CenteredLayout from "../../layouts/CenteredLayout";
import ButtonSimple from "common/src/components/Interface/ButtonSimple";

export default function Tiempos(props) {

    const [data, setData] = useState([]);
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [loading, setLoading] = useState(true);
    const {navigation } = props;

    useEffect(() => {
      console.log('useEffect')
      loadData()
    }, []);

    const loadData = () => {
      console.log('loadData')
      console.log({page: page})
      console.log(authenticationService.currentUserValue.id)
        let nextPage = page + 1;
        console.log({nextPage: nextPage})

        const endpoint = API_URL + EPS.TIEMPO + '?fields=inicio,fin,id,usuario_id&sort=-inicio&usuario_id='+authenticationService.currentUserValue.id+'&page='+ nextPage;
        const bt = { headers: {...authHeader()} };

        if (!bt.headers.Authorization) {
            setLoading(false);
            return;
        }

        const config = {
            headers: {Authorization: bt.headers.Authorization},
        };

        axios
          .get(endpoint, config)
          .then(d => {
              if(d.data.status === 403)
                  return;

              console.log({xPaginationCurrentPage: d.headers['x-pagination-current-page']})

              setData(data.concat(d.data));
              setPage(parseInt(d.headers['x-pagination-current-page']))
              setMaxPage(parseInt(d.headers['x-pagination-page-count']))
              setLoading(false);
          })
          .catch(e => console.log(e));
    }

    const loadMore = () => {
        console.log('loadMore')
        if (page > maxPage)
          return;

        loadData();
    }


    return (
      <CenteredLayout title={"Listado de tiempos"}>
          {loading ? (
            <ActivityIndicator/>
          ) : (
            <div>
                <TiempoListar data={data} navigation={navigation}/>
                {page < maxPage && <ButtonSimple
                  title="Cargar anteriores"
                  onPress={loadMore}
                />}
            </div>
          )
          }
      </CenteredLayout>
    )
      ;
}
