import React, { Component } from "react";
import axios from "axios";
import {
  ActivityIndicator,
  Button,
  KeyboardAvoidingViewComponent,
  Platform,
  Text,
  TouchableOpacity,
  View,
  KeyboardAvoidingView
} from "react-native";
import Modal from "../Modal";
import moment from "moment";
import Firma from "common/src/components/Firma";
import Timer from "common/src/components/Timer";
import { API_URL, EPS } from "../../constants/Constants";
import Geolocation from "@react-native-community/geolocation";
import Alert from "common/src/components/Alert";
import SelectDateTime from "common/src/components/SelectDateTime";
import Descripcion from "common/src/components/Descripcion";
import styles from "./styles";
import ButtonSimple from "../Interface/ButtonSimple";
import ReactGA from "react-ga";
import RNDateTimePicker from "@react-native-community/datetimepicker";
import { AppContext } from "../../Context/AppContext";
import { getTimeZone } from "../../util/Date";
import Icon from "react-native-vector-icons/dist/SimpleLineIcons";
import Colors from "../../constants/Colors";
import {analyticsTrackEvent} from "web/src/hooks/withGaTracker";

ReactGA.initialize("UA-145457735-3");

class IniciarDetenerJornadaComponent extends Component {
  static contextType = AppContext;
  minDate = moment("2000-01-01");

  constructor(props) {
    super(props);
    this.state = {
      changeHourVisible: false,
      date: null,
      descripcion: "",
      descripcionVisible: false,
      error: "",
      firma: null,
      firmaVisible: false,
      loading: this.props.loading,
      modalVisible: false,
      mostrarFirma: false,
      seleccionHoraVisible: false,
      screenHeight: null,
      screenWith: null,
      cambiarFechaAndroidVisible: false,
      cambiarFechaWebVisible: false,
      pinUser: false,
      validNewDate: false
    };
    this.dateWebRef = React.createRef();
  }

  componentDidMount() {
    // Si es usuario pin, lanzamos lo redirecionamos directamtente a iniciar/detener jornada
    if (Platform.OS === "web" && this.context?.currentUser?.rol === 4) {
      this.setState({ pinUser: true });
      this.state.working
        ? this.handleDetenerJornada()
        : this.handleIniciarJornada();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const res = { ...props, ...state };
    return res;
  }

  startTimer = () => {
    this.comprobarEstadoTrabajandoTimer = setInterval(
      this.comprobarEstadoTrabajando,
      30000
    );
  };

  clearTimer = () => {
    clearInterval(this.comprobarEstadoTrabajandoTimer);
  };

  handleTieneQueFirmar = () => {
    const fin = moment(this.state.date);
    const inicio = moment(this.state.workingStartTime, "DD-MM-YYYY hh:mm:ss");

    if (this.state.working && fin.diff(inicio) <= 0) {
      Alert(
        "Error",
        "No puedes finalizar antes de la hora que empezaste a trabajar (" +
          this.state.workingStartTime +
          ")."
      );
      this.setState({ loading: false });
    } else {
      //TODO - Revisar cuando se pase a la siguiente fase con log
      const empresaData = JSON.parse(localStorage.getItem("empresaData"));
      const userData = JSON.parse(localStorage.getItem("userData"));

      const firma = empresaData?.firma ?? 2;
      const debeFirmar = userData?.debeFirmar ?? true;

      console.log("Firma configurada a " + firma);
      // const FIRMA_NULA = 0; //ningún usuario firma
      // const FIRMA_INTERMEDIA = 1; //trabajadores asignados a encargados
      // const FIRMA_TODOS = 2; // todos firman

      if (debeFirmar) {
        this.setState({
          modalVisible: true,
          seleccionHoraVisible: false,
          firmaVisible: true
        });
      } else {
        this.setState({
          modalVisible: false,
          seleccionHoraVisible: false,
          firmaVisible: false
        });
        if (this.state.working) {
          this._handleDetenerJornada();
        } else {
          this._handleIniciarJornada();
        }
      }
    }
  };

  handleFirmar = (img, field) => {
    console.log(img);
    this.setState(
      {
        modalVisible: false,
        firmaVisible: false,
        [field]: img
      },
      () => {
        if (this.state.working) {
          this._handleDetenerJornada();
        } else {
          this._handleIniciarJornada();
        }
      }
    );
  };

  handleIniciarJornada = () => {
    console.log("handleIniciarJornada");
    const token = this.props.token;
    const { trabajadorId } = this.props;

    const config = {
      headers: { Authorization: "Bearer " + token }
    };

    const trabajadorIdQueryParam =
      trabajadorId !== null ? "?id=" + trabajadorId : "";

    let timezone =
      (trabajadorIdQueryParam.length > 0 ? " &" : "?") +
      "timezone=" +
      getTimeZone();

    axios
      .get(
        API_URL + EPS.USER_FECHA_INICIO_FIN + trabajadorIdQueryParam + timezone,
        config
      )
      .then(response => {
        console.log(response);

        if (!response.data.data) {
          console.log(response.data);
          this.context.onIniciarDetenerDone({
            action: "iniciar",
            result: false,
            error: response.data.error_code
          });
          switch (response.data.error_code) {
            case "maximos_inicios_sesion_grupo_horario_excedido":
              Alert(
                "Error",
                "Ya has llegado al máximo de jornadas que tenías que iniciar hoy!"
              );
              break;
            case "festivo":
              Alert(
                "Error",
                "Tu empresa ha marcado el día de hoy como festivo. Disfrútalo ;)"
              );
              break;
            case "vacaciones_libre":
              Alert(
                "Error",
                "Tu empresa ha marcado este día como Vacaciones / Libre."
              );
              break;
            default:
              Alert("Error", "No es posible iniciar jornada.");
          }
        } else
          this.setState({
            modalVisible: true,
            seleccionHoraVisible: true,
            firmaVisible: false,
            date: moment(response.data.data).toDate()
          });
      })
      .catch(error => {
        console.log("error get jornada");
        console.log(error);
        this.setState({ loading: false });
      });
  };

  convertDate(date) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }

    return (
      [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join(
        "-"
      ) +
      " " +
      [
        pad(date.getHours()),
        pad(date.getMinutes()),
        pad(date.getSeconds())
      ].join(":")
    );
  }

  _handleIniciarJornada = async () => {
    console.log("_handleIniciarJornada");
    this.setState({ loading: true });

    const token = this.props.token;
    const trabajadorId =
      this.props.trabajadorId !== null ? "?id=" + this.props.trabajadorId : "";

    const config = {
      headers: { Authorization: "Bearer " + token }
    };

    let position = false;
    if (Platform.OS !== "web") position = await this.getLocation();

    axios
      .post(
        API_URL + EPS.USER_INICIAR + trabajadorId,
        {
          firma_inicio: this.state.firma,
          inicio: this.convertDate(this.state.date),
          latitud:
            position !== false ? position.coords.latitude.toString() : "",
          longitud:
            position !== false ? position.coords.longitude.toString() : "",
          descripcion_inicio: this.state.descripcion,
          client_timezone: getTimeZone()
        },
        config
      )
      .then(data => {
        if (data.data.status) {
          this.context.onIniciarDetenerDone({
            action: "iniciar",
            result: true
          });
          this.setState({
            working: data.data.status,
            workingStartTime: this.convertDate(this.state.date)
          });
          this.props.jornadaChange && this.props.jornadaChange();
          analyticsTrackEvent('start_working');
        } else {
          console.log(data);
          if (data.data.data.message === "Empresa no activa")
            Alert(
              "Error",
              "La configuración actual de tu empresa no permite iniciar / finalizar jornada. Ponte en contacto con la persona responsable"
            );
          else Alert("Error", "Error al iniciar jornada");
        }

        this.handleChangeDescripcion("");
        this.setState({ loading: false });
      })
      .catch(error => {
        console.log("error handleIniciarJornada");
        console.log(error);
        Alert("Error", "Error accediendo al servidor (1)");
        this.setState({ loading: false });
      })
      .then(() => {
        if (!position) {
          this.enableLocationWarning();
        }
      });
  };

  handleDetenerJornada = () => {
    const token = this.props.token;
    const { trabajadorId } = this.props;

    const config = {
      headers: { Authorization: "Bearer " + token }
    };

    const trabajadorIdQueryParam =
      trabajadorId !== null ? "?id=" + trabajadorId : "";

    let timezone =
      (trabajadorIdQueryParam.length > 0 ? " &" : "?") +
      "timezone=" +
      getTimeZone();

    axios
      .get(
        API_URL + EPS.USER_FECHA_INICIO_FIN + trabajadorIdQueryParam + timezone,
        config
      )
      .then(response => {
        this.setState({
          modalVisible: true,
          seleccionHoraVisible: true,
          firmaVisible: false,
          date: moment(response.data.data).toDate()
        });
        console.log(response.data.data);
      })
      .catch(error => {
        console.log("error get jornada");
        console.log(error);
        this.setState({ loading: false });
      });
  };

  _handleDetenerJornada = async () => {
    const token = this.props.token;
    const trabajadorId =
      this.props.trabajadorId !== null ? "?id=" + this.props.trabajadorId : "";

    const config = {
      headers: { Authorization: "Bearer " + token }
    };

    this.setState({ loading: true });

    // en web no se va a usar la localización
    let position = false;
    if (Platform.OS !== "web") position = await this.getLocation();

    axios
      .post(
        API_URL + EPS.USER_DETENER + trabajadorId,
        {
          firma_fin: this.state.firma_fin ?? "",
          fin: this.convertDate(this.state.date),
          latitud_fin:
            position !== false ? position.coords.latitude.toString() : "",
          longitud_fin:
            position !== false ? position.coords.longitude.toString() : "",
          descripcion_fin: this.state.descripcion,
          client_timezone: getTimeZone()
        },
        config
      )
      .then(data => {
        console.log(data.data);
        if (data.data.status) {
          this.context.onIniciarDetenerDone({
            action: "detener",
            result: true
          });
          this.setState({ working: false });
        } else {
          if (data.data.data.message === "Empresa no activa")
            Alert(
              "Error",
              "La configuración actual de tu empresa no permite iniciar / finalizar jornada. Ponte en contacto con la persona responsable"
            );
          else Alert("Error", "Error al detener la jornada");
        }

        this.handleChangeDescripcion("");
        this.props.jornadaChange && this.props.jornadaChange();
        this.setState({ loading: false });
        analyticsTrackEvent('stop_working');
      })
      .catch(error => {
        console.log("error handleDetenerJornada");
        console.log(error);
        Alert("Error", "Error accediendo al servidor (2)");
        this.setState({ loading: false });
      })
      .then(() => {
        if (!position) {
          this.enableLocationWarning();
        }
      });
  };

  /**
   * Por el momento solamente modifica la descripción de fin
   * Text string
   */
  handleChangeDescripcion = text => {
    this.setState({ descripcion: text });
  };

  setDate = (event, date) => {
    // en android se dispara el evento cuando se le da al OK del modal. En ios, cuando se para el spinner.
    const android = Platform.OS === "android";
    console.log("setDate");
    console.log(date);
    // this.setState({date, changeHourVisible: true});

    if (typeof date !== "undefined") {
      this.setState({ date, changeHourVisible: !android });
    } else {
      this.setState({ changeHourVisible: !android });
    }

    // console.log(date);
    // const ios = Platform.OS === 'ios';
    // if (typeof date !== 'undefined') {
    //     this.setState({date, changeHourVisible: ios});
    // } else {
    //     this.setState({changeHourVisible: ios});
    // }
  };

  getLocation = () => {
    console.log("getlocation");
    // Geolocation.watchPosition(s => {
    //   console.log('watch position detected');
    //   console.log(s);
    // });
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition(
        ok => {
          console.log("getLocation promise resolution");
          console.log(ok);
          resolve(ok);
        },
        e => {
          console.log("getLocation promise rejection");
          console.log(e);
          reject(e);
        },
        {
          enableHighAccuracy: Platform.OS === "ios",
          timeout: 10000,
          maximumAge: 1000
        }
      );
    }).catch(e => {
      console.log("getlocation catch", e);
      return false;
    });
  };

  enableLocationWarning() {
    const title = "No es posible determinar la localización";
    let message = "";

    switch (Platform.OS) {
      case "web":
        return;
        break;
      case "ios":
        message =
          "No ha sido posible guardar tu localización, ya que no se han concedido los permisos necesarios. Dirijase a: Ajustes - Control de jornada laboral - Localización";
        break;
      default:
        message = "No ha sido posible guardar tu localización";
        break;
    }
    Alert(title, message);
  }

  handleChangeHour = () => {
    this.setState({
      cambiarFechaWebVisible: false,
      changeHourVisible: true,
      descripcionVisible: false
    });
  };

  handleCerrarDateTime = () => {
    this.setState({
      changeHourVisible: false
    });
  };

  handleCerrarFirma = () => {
    if (this.state.pinUser) this.props.onCancelPinButton();

    this.setState({
      modalVisible: false,
      firmaVisible: false
    });
  };

  handleCerrarIniciarDetener = () => {
    this.setState({
      modalVisible: false,
      seleccionHoraVisible: false
    });
  };

  handleAgregarDescripcion = () => {
    this.setState(
      {
        changeHourVisible: false,
        descripcionVisible: true,
        seleccionHoraVisible: false
      },
      () => {
        if (
          this._textInputObservacionesRef !== null &&
          typeof this._textInputObservacionesRef !== "undefined"
        ) {
          this._textInputObservacionesRef.focus();
        }
      }
    );
  };

  handleChangeDate = () => {
    if (
      this.dateWebRef.current.value &&
      moment(this.dateWebRef.current.value).isValid()
    ) {
      const newDate = moment(this.state.date);

      const selectedDate = moment(this.dateWebRef.current.value);
      newDate.set("year", selectedDate.year());
      newDate.set("month", selectedDate.month());
      newDate.set("date", selectedDate.date());

      this.setState({
        cambiarFechaWebVisible: false,
        date: newDate.toDate()
      });
    } else {
      console.error("Fecha no válida");
    }
  };

  handleCancelChangeDate = () => {
    console.log(
      "Class: IniciarDetenerJornadaComponent, Function: handleCancelChangeDate, Line 557 (): "
    );
    this.setState({ ...this.state, cambiarFechaWebVisible: false });
  };

  handleCerrarDescipcion = texto => {
    this.setState({
      descripcionVisible: false,
      seleccionHoraVisible: true,
      descripcion: texto
    });
  };

  handleCancelDescripcion = () => {
    this.setState({ descripcionVisible: false, seleccionHoraVisible: true });
  };

  render() {
    const { working, loading } = this.state;
    return (
      <View>
        {loading ? (
          <ActivityIndicator />
        ) : working ? (
          <View styles={styles.wrapperContainer}>
            <Timer date={this.state.workingStartTime} />
            <ButtonSimple
              title={"Detener jornada"}
              onPress={this.handleDetenerJornada}
            />
          </View>
        ) : (
          <>
            <View styles={styles.wrapperContainer}>
              <Button
                title={"Iniciar Jornada"}
                onPress={this.handleIniciarJornada}
              />
            </View>
          </>
        )}
        <Modal
          visible={this.state.modalVisible}
          animationType="slideUp"
          animationInTiming={5000}
          onRequestClose={() => {
            console.log("on request close padre");
            if (this.state.pinUser) this.props.onCancelPinButton();

            this.setState({
              modalVisible: false,
              changeHourVisible: false,
              descripcionVisible: false,
              firmaVisible: false,
              mostrarFirma: false,
              seleccionHoraVisible: false,
              cambiarFechaAndroidVisible: false,
              cambiarFechaWebVisible: false
            });
          }}
        >
          <View style={{ flex: 1, paddingBottom: 50 }}>
            {this.state.seleccionHoraVisible && (
              // seleccion de hora
              <View style={styles.subContainer}>
                {this.state.date && (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        marginBottom: 10
                      }}
                    >
                      Vas a {working ? "detener" : "iniciar"} jornada el:
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        marginBottom: 10
                      }}
                    >
                      {moment(this.state.date).format("DD-MM-YYYY HH:mm:ss")}
                    </Text>
                  </>
                )}
                {!this.state.changeHourVisible &&
                  !this.state.cambiarFechaWebVisible && (
                    <>
                      <View style={styles.iniciarButtonWrapper}>
                        <ButtonSimple
                          title={working ? "Detener" : "Iniciar"}
                          onPress={this.handleTieneQueFirmar}
                          style={styles.buttonStyles}
                        />
                      </View>
                      <View style={{ marginBottom: 5 }}>
                        <Button
                          title="Cancelar"
                          color={Colors.btCancel}
                          onPress={
                            this.state.pinUser
                              ? this.props.onCancelPinButton
                              : this.handleCerrarIniciarDetener
                          }
                        />
                      </View>
                    </>
                  )}
                {/*{!this.state.changeHourVisible &&
                  !this.state.cambiarFechaWebVisible && (
                    <View>
                      <Button
                        color="#dc3545"
                        title={
                          "Volver sin " +
                          (working ? "detener" : "iniciara") +
                          " jornada"
                        }
                        onPress={
                          this.state.pinUser
                            ? this.props.onCancelPinButton
                            : this.handleCerrarIniciarDetener
                        }
                      />
                    </View>
                  )}*/}

                {this.state.descripcion.length > 0 && (
                  <View style={{ marginTop: 20, marginBottom: 20 }}>
                    <Text
                      style={{
                        textDecorationLine: "underline",
                        color: "grey",
                        marginBottom: 10
                      }}
                    >
                      Descripción:
                    </Text>
                    <TouchableOpacity onPress={this.handleAgregarDescripcion}>
                      <Text style={{ color: "grey" }}>
                        {this.state.descripcion}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

                {this.state.cambiarFechaAndroidVisible && (
                  <RNDateTimePicker
                    mode="date"
                    maximumDate={new Date()}
                    onChange={(e, d) => {
                      let newDate = moment(this.state.date);
                      const selectedDate = moment(d);

                      newDate.set("year", selectedDate.year());
                      newDate.set("month", selectedDate.month());
                      newDate.set("date", selectedDate.date());
                      console.log("newDate", newDate);

                      console.log("movil");
                      console.log(newDate.toDate());
                      // console.log(this.state.date)
                      this.setState({
                        cambiarFechaAndroidVisible: false,
                        date: newDate.toDate()
                      });
                    }}
                    value={this.state.date}
                  />
                )}

                {this.state.cambiarFechaWebVisible && (
                  <div>
                    <input
                      type="date"
                      style={{
                        boxSizing: "border-box",
                        marginTop: 20,
                        width: "100%",
                        padding: 6
                      }}
                      ref={this.dateWebRef}
                      onChange={e => {
                        const { value } = e.target;

                        if (moment(value).isAfter(this.minDate))
                          this.setState({
                            validNewDate: true
                          });
                        else
                          this.setState({
                            validNewDate: false
                          });
                      }}
                    />
                    <div className="row">
                      <button
                        disabled={!this.state.validNewDate}
                        onClick={this.handleChangeDate}
                        className="btn-primary"
                        style={{ width: "100%", marginRight: "2px" }}
                      >
                        Aceptar
                      </button>
                      <button
                        onClick={this.handleCancelChangeDate}
                        className="btn-cancel"
                        style={{ width: "100%", marginLeft: "2px" }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                )}

                <View style={styles.buttonCalendarWrapper}>
                  {this.state.changeHourVisible && (
                    <SelectDateTime
                      date={this.state.date}
                      display="spinner"
                      setDate={this.setDate}
                      cerrarSelectDateTime={this.handleCerrarDateTime}
                    />
                  )}

                  {!this.state.changeHourVisible &&
                    !this.state.descripcionVisible &&
                    !this.state.cambiarFechaWebVisible && (
                      <View style={rowDescripcionHora}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between"
                          }}
                        >
                          <TouchableOpacity
                            onPress={this.handleChangeHour}
                            style={styles.iconButtons}
                          >
                            <View style={{ alignItems: "center" }}>
                              <Icon name="clock" color="#313638" size={20} />
                            </View>
                          </TouchableOpacity>

                          {Platform.OS !== "ios" && (
                            <TouchableOpacity
                              onPress={() => {
                                if (Platform.OS === "android")
                                  this.setState({
                                    cambiarFechaAndroidVisible: true
                                  });

                                if (Platform.OS === "web")
                                  this.setState(
                                    {
                                      cambiarFechaWebVisible: true,
                                      validNewDate: false
                                    },
                                    () => {
                                      setTimeout(() => {
                                        this.dateWebRef.current.click();
                                      }, 100);
                                    }
                                  );
                              }}
                              style={styles.iconButtons}
                            >
                              <View style={{ alignItems: "center" }}>
                                <Icon
                                  name="calendar"
                                  color="#313638"
                                  size={20}
                                />
                              </View>
                            </TouchableOpacity>
                          )}

                          <TouchableOpacity
                            onPress={this.handleAgregarDescripcion}
                            style={styles.iconButtons}
                          >
                            <View style={{ alignItems: "center" }}>
                              <Icon name="pencil" color="#313638" size={20} />
                            </View>
                          </TouchableOpacity>
                        </View>

                        {/*<ButtonSimple
                          title="Cambiar Hora"
                          onPress={this.handleChangeHour}
                          color="#333"
                        />

                        {Platform.OS !== "ios" && (
                          <ButtonSimple
                            title="Cambiar Fecha"
                            onPress={() => {
                              if (Platform.OS === "android")
                                this.setState({
                                  cambiarFechaAndroidVisible: true
                                });

                              if (Platform.OS === "web")
                                this.setState(
                                  {
                                    cambiarFechaWebVisible: true,
                                    validNewDate: false
                                  },
                                  () => {
                                    setTimeout(() => {
                                      this.dateWebRef.current.click();
                                    }, 100);
                                  }
                                );
                            }}
                            color="#333"
                          />
                        )}

                        <ButtonSimple
                          title={
                            (this.state.descripcion.length > 0
                              ? "Modificar"
                              : "Agregar") + " descripción"
                          }
                          onPress={this.handleAgregarDescripcion}
                          color="#333"
                        />*/}
                      </View>
                    )}
                </View>
              </View>
            )}

            {this.state.firmaVisible && (
              // Firma
              <Firma
                styles={styles}
                working={working}
                iniciarJornada={this._handleIniciarJornada}
                detenerJornada={this._handleDetenerJornada}
                ocultarFirma={this.handleCerrarFirma}
                webStyle={webStyle}
                firmar={this.handleFirmar}
              />
            )}

            {this.state.descripcionVisible && (
              // Descripción
              <KeyboardAvoidingView>
                <Descripcion
                  descripcion={this.state.descripcion}
                  changeDescripcion={this.handleChangeDescripcion}
                  cancelDescripcion={this.handleCancelDescripcion}
                  cerrarDescripcion={this.handleCerrarDescipcion}
                  focus={c => (this._textInputObservacionesRef = c)}
                />
              </KeyboardAvoidingView>
            )}
          </View>
        </Modal>
      </View>
    );
  }
}

const rowDescripcionHora = {
  ...Platform.select({
    android: {
      flexDirection: "column",
      justifyContent: "space-between",
      height: 150
    },
    web: {
      position: "absolute",
      bottom: -110,
      left: -60,
      right: -60
    }
  })
};

const webStyle = `.m-signature-pad--footer
                        .button {
                        background: none;
                        color: #157efb;
                        font-size: 16px;
                    }
                        @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        .m-signature-pad {
                        margin: 0%;
                    }
                    }`;

const styleSignatureShadow = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1
  },
  shadowOpacity: 0.22,
  shadowRadius: 2.22,
  elevation: 3
};

export default IniciarDetenerJornadaComponent;
