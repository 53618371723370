import {API_URL, EPS} from "../constants/Constants";
import axios from "axios";

/**
 * Devuelve una promesa que va a devolver a una cadena con el mensaje
 * o a false en caso de que no haya mensaje o de que este esté fuera de fechas
 * @param access_token string
 * @returns {Promise.Promise} string | bool
 */
const postImage = async (access_token, image) => {

    return new Promise((resolve, reject) => {

        console.log('calling: ' + API_URL + EPS.TIEMPO_IMAGE)
        const config = {
            headers: {Authorization: 'Bearer ' + access_token},
        };

        axios.post(
            API_URL + EPS.TIEMPO_IMAGE,
            {image},
            config,
        )
            .then(data => resolve(data))
            .catch(error => reject(error))
    })

}

const notificarNoImagenPin = (access_token) => {
    console.log('tiempo service >> Notificar no pin')

    const config = {
        headers: {Authorization: 'Bearer ' + access_token},
    };

    return axios.get(
      API_URL + EPS.EMPRESA_NOTIFICAR_ERROR_IMAGEN_PIN,
      config,
    )
      .then(data => data)
      .catch(error => alert('Error al conectar al servidor.'))
}


export default {
    postImage,
    notificarNoImagenPin
}
