import React, {useEffect, useState} from 'react';
import {Router, Route} from 'react-router-dom';
import { history} from "./helpers/history";
import {authenticationService} from "./services/authentication.service";
import Login from "./pages/Login/index";
import axios from "axios";
import {authHeader} from "./helpers/auth-header";
import {API_URL, EPS, UPLOADS_URL} from "common/src/constants/Constants";
import './scss/styles.scss';
import withGaTracker, {analyticsLogInUser} from './hooks/withGaTracker';
import LogRocket from 'logrocket';
// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';
import Main from "./layouts/Main";
import TrabajadorRoutes from "./routes/TrabajadorRoutes";
import PinRoutes from "./routes/PinRoutes";
import WebcamComponent from "./components/WebcamComponent";
import {Platform} from "react-native";
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: SimpleLineIcons;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
} else {
    style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        authenticationService.logout();
    }
    return error;
});

axios.interceptors.request.use(request => {
    request.headers.cjlClientPlatformOS = 'web';
    return request
});

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        onlyLogs: true,
        titleColor: "green",
        diffNameColor: "aqua"
    });
}
else {
    LogRocket.init('e1f2eo/trabajadorcontroldejornadalaborales');
}


const App = props => {

    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const empresaData = JSON.parse(localStorage.getItem('empresaData'));


    /**
     * chequeamos el access token si es que el usuario está logeado
     */
    useEffect(() => {

        const bt = { headers: {...authHeader()} };

        if (!bt.headers.Authorization) {
            setLoading(false);
            return;
        }

        axios.get(API_URL + EPS.USER_CHECK_ACCESS_TOKEN, bt).then(d => {
            if (!d.data.status)
                authenticationService.logout()
        }).catch(e => {
            authenticationService.logout()
        }).finally(a =>
            setLoading(false)
        );
    }, []);

    useEffect(() => {
        if (currentUser)
            analyticsLogInUser(currentUser, empresaData)
    }, [currentUser])

    /**
     * Pillamos el usuario logeado actual
     */
    useEffect(() => {
        authenticationService.currentUser.subscribe(x => {
            console.log('seteo usuario')
            setCurrentUser(x)
        });
    });

    useEffect(() => {
        if (currentUser === null || process.env.NODE_ENV === 'development') return

        LogRocket.identify(currentUser.id, {
            name: currentUser.id,
            email: currentUser.email
        });

    }, [currentUser])

    return (<div className="main-wrapper">{loading ? (
        <div>Cargando, espere...</div>
    ): (
        <Router history={history}>
            <Main currentUser={currentUser}>
                {currentUser && currentUser.rol === 0 &&  <TrabajadorRoutes />}
                {currentUser && currentUser.rol === 4 &&  <PinRoutes />}
                <Route path="/login" exactly component={withGaTracker(Login)} />
            </Main>
        </Router>
    )}</div>);
}

App.whyDidYouRender = true;


export default App;
