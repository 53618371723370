import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Button, View, Text} from "react-native";
import {API_URL, EPS} from "common/src/constants/Constants";
import PropTypes from "prop-types";
import {authHeader} from "web/src/helpers/auth-header";
import axios from "axios";
import Modal from "../Modal";
import Checkbox from "mobile/components/Checkbox";
import Colors from "../../constants/Colors";


const FicheroListItem = ({item, isDownloading, setIsDownloading, isShowingConfirmationModal, setIsShowingConfirmationModal, onChange}) => {

    const [loading, setLoading] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmCheckboxChecked, setConfirmCheckboxChecked] = useState(false)

    useEffect(() => {
      setIsShowingConfirmationModal(showConfirmationModal)
    }, [showConfirmationModal])

    const handleClick = () => {
        if (isDownloading || loading || isShowingConfirmationModal)
            return false;

        if (!item.mostrar_confirmacion_lectura){

          setLoading(true);
          return downloadFile();
        }

        setShowConfirmationModal(true)
    }

    const handleConfirm = () => {
      setShowConfirmationModal(false)
      setConfirmCheckboxChecked(false)
      item.mostrar_confirmacion_lectura = false;
      return downloadFile();
    }

    const handleCancel = () => {
      setShowConfirmationModal(false);
      setConfirmCheckboxChecked(false);
    }

    const downloadFile = () => {
      const endpoint = API_URL + EPS.FICHERO;
      const btd = { headers: {...authHeader()} };
      setIsDownloading(true);

      if (!btd.headers.Authorization) {
        setLoading(false);
        setIsDownloading(false);
        return;
      }

      const config = {
        headers: {Authorization: btd.headers.Authorization},
        params: {id: item.id},
        responseType: 'blob'
      };

      axios
        .get(endpoint, config)
        .then(d => {

          if (d.status !== 200){
            alert('Hay un error al descargar el fichero. Por favor, contacta con tu empresa.')
            return false;
          }

          const blob = d.data;
          var link = document.createElement('a');


          link.href = window.URL.createObjectURL(blob);

          console.log(blob.type)

          link.download=item.nombre + ".pdf";
          link.click();
          setTimeout(function(){
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(item);
            item.mostrar_confirmacion_lectura = false;
            onChange(item)
          }, 100);

          setIsDownloading(false);
          setLoading(false);
        })
        .catch(e => {
          console.log(e)
          setLoading(false);
          setIsDownloading(false);
        });
    }

    return <>
        <div
          style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              borderBottom: "1px solid",
              borderBottomColor: '#ccc',
              marginBottom: 18,
              paddingBottom: 4
          }}
        >
            <span
                style={{
                    cursor: "pointer",
                    color: isDownloading || loading ? '#666' : 'rgb(33, 150, 243)',
                    display: "flex",
                }}
                onClick={() => handleClick()}

            >
                {item.nombre}
                <span style={{paddingLeft: 5}}>{loading && <ActivityIndicator />}</span>
            </span>

        </div>
        <Modal
          visible={showConfirmationModal}
          animationType="slideUp"
          animationInTiming={5000}
          onRequestClose={handleCancel}
        >
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              padding: 40,
            }}>
              <p>Su empresa necesita que confirme la recepción de este documento</p>
              <p>
                <input type="checkbox" id="confirmoRecepcion" name="confirmoRecepcion" value="" onClick={() => setConfirmCheckboxChecked(!confirmCheckboxChecked)}  />
                <label htmlFor="confirmoRecepcion">Confirmo la recepción</label>
              </p>
              <div style={{ display: 'flex', gap: 15}}>
                <Button
                  title="Confirmar"
                  onPress={handleConfirm}
                  color={Colors.btPrimary}
                  disabled={!confirmCheckboxChecked}
                />
                <Button
                  title="Cancelar"
                  color={Colors.btCancel}
                  onPress={handleCancel}
                />
              </div>
          </div>
        </Modal>
    </>
}

FicheroListItem.propTypes = {
    item: PropTypes.object.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    setIsDownloading: PropTypes.func.isRequired,
    isShowingConfirmationModal: PropTypes.bool.isRequired,
    setIsShowingConfirmationModal: PropTypes.func.isRequired,
    onChange: PropTypes.func,
};

export default FicheroListItem;
