import React, {useContext, useEffect, useRef} from 'react';
import styles from '../scss/pin_layout.module.scss';
import {AppContext} from "common/src/Context/AppContext";
import Webcam from "react-webcam";
import TiempoService from "common/src/services/TiempoService";
import PinFooter from "../pages/Pin/PinFooter";

const PinLayout = props => {

    const AP = useContext(AppContext)
    const webcamRef = useRef(null);


    // TODO: corregir
    // ojo, esto hace capturas siempre que ha cambiado el tiempo. No tiene por qué haber sido correcto el inicio o detención.
    useEffect(() => {
        console.log('useEffect AP.lastIniciarDetenerJornadaStatus', AP.lastIniciarDetenerJornadaStatus)
        if (!AP.lastIniciarDetenerJornadaStatus || !AP.lastIniciarDetenerJornadaStatus.result === true) {
            console.log('return null, oculto webcam');
            AP.setPinWebcamVisible(false);
            return
        }

        async function getScreenshoot() {
            console.log('capture start');
            const image = capture()
            console.log(AP.lastUserData)
            AP.setLastUserData(null)

            if (!image){
                console.log('No imagen, llamo al backend para avisar al administrador');
                TiempoService.notificarNoImagenPin(AP.lastUserData.token)
            }
            else{
                console.log('Hay imagen, la enviamos al backend.')
                const postImageRes = await TiempoService.postImage(AP.lastUserData.token, image)
                console.log(postImageRes)
            }

            AP.setPinWebcamVisible(false);
            console.log('capture end');
        }

        getScreenshoot();


    }, [AP.lastIniciarDetenerJornadaStatus])


    const capture = React.useCallback(() => {
        return webcamRef.current.getScreenshot({width: 1920, height: 1080});
    }, [webcamRef]);


    return <>
        <div className={styles.wrapper}>
            <div className={styles.video}>

                {AP.pinWebcamVisible && <Webcam
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={60}
                />}

            </div>

            <div className={styles.topvideo} />

            <div className={styles.main}>
                <div className={styles.mainContent}>
                    {props.children}
                </div>
            </div>
        </div>
    </>
}

export default PinLayout;
