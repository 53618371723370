import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';

const Checkbox = (props) => {

    const [checked, setChecked] = useState(false);

    const checkClicked = async () => {
        setChecked(!checked) // setState is async function.
        props.onChange && props.onChange(!checked);
    }

    return (
        <TouchableOpacity onPress={checkClicked} style={props.style}>
            <View
                style={{ flexDirection: "row" }}
            >
                <View style={{
                    height: 15,
                    width: 15,
                    borderWidth: 1,
                    borderColor: '#000',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 5
                }}>
                    <View style={{
                        height: 7,
                        width: 7,
                        backgroundColor: checked ? '#000' : '#FFF',
                    }} />
                </View>
                {props.children}
            </View>
        </TouchableOpacity>
    )

}

export default Checkbox;