/*
TODO
- Revisar si está bien hecho.
- Revisar el tamaño de la firma.
*/
import SignatureCanvas from "react-signature-canvas";
import Alert from "../Alert";
import React, { Component, useEffect } from "react";
import { Button, Text, View } from "react-native";

export default function signature(props) {
  let sigPad = {};

  const clear = () => {
    sigPad.clear();
  };

  const show = () => {
    if (sigPad.isEmpty()) {
      Alert(
        "Firma Obligada",
        "Es obligado firmar",
        [
          {
            text: "Ok",
            onPress: () => {
              props.ocultarFirma;
            }
          }
        ],
        { cancelable: false }
      );
    } else {
      const field = props.working ? "firma_fin" : "firma";
      props.firmar(sigPad.toDataURL("image/png"), field);
    }
  };

  let buttonsStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
    margin: "0 auto"
  };

  const mq500 = window.matchMedia("(max-width: 500px");

  if (mq500.matches) {
    console.log("< 500px", window.innerWidth);
    buttonsStyles = {
      ...buttonsStyles,
      width: 375,
      marginRight: "auto",
      marginLeft: "auto"
    };
  }

  return (
    <>
      <View style={{ height: 275, marginBottom: 20 }}>
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          ref={ref => {
            sigPad = ref;
          }}
        />
        <View style={buttonsStyles}>
          <View style={{ width: "49%" }}>
            <Button
              title={props.working ? "Detener" : "Iniciar"}
              onPress={(e) => {
                e.preventDefault()
                show();
              }}
            />
          </View>
          <View style={{ width: "49%" }}>
            <Button color="#dc3545" title={"Borrar"} onPress={clear} />
          </View>
        </View>
      </View>
    </>
  );
}
