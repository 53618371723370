import React, {useEffect, useState} from 'react';
import Component from './Component';
import PropTypes from 'prop-types';
import Alert from "../Alert/index.web";
import { ActivityIndicator } from 'react-native';
import util from './util';

const IniciarDetenerJornada = props => {

    const [stateData, setStateData] = useState({
        loading: true,
        working: false,
        workingStartTime: '',
    });

    useEffect(() => {
        util.comprobarEstadoTrabajando(props.token, props.trabajadorId)
            .then(res => {
                const [loading, working, workingStartTime] = res;

                setStateData({loading, working, workingStartTime});

            })
            .catch(error => {
                const [loading, working, workingStartTime] = error;
                setStateData({loading, working, workingStartTime});
                Alert('Error', 'Error accediendo al servidor en web');
            });
    }, []);

    const {loading, working, workingStartTime} = stateData;

    return <>
        {loading ?
            <ActivityIndicator /> :
        (
            <div style={{position: 'relative', zIndex: 1}}>
                <Component {...{working, workingStartTime, ...props}} />
            </div>
        )}
    </>;
};

IniciarDetenerJornada.propTypes = {
    token: PropTypes.string.isRequired,
    trabajadorId: PropTypes.number,
};

IniciarDetenerJornada.defaultProps = {
    trabajadorId: null,
};

export default IniciarDetenerJornada;
