import React from 'react';

const Guest = ({children}) => <div className="row">
    <div className="col jutify-content-center align-items-center d-flex-column login-banner">
        <h1>Trabaja desde casa<br />Ficha desde casa</h1>
        <h2>#yomequedoencasa</h2>
        <h2>#yotrabajoencasa</h2>
    </div>
    <div className="col jutify-content-center align-items-center d-flex-column">
        {children}
    </div>
</div>

export default Guest;
