const tipos = [
  {
    id: 1,
    name: 'Vacaciones',
  },
  {
    id: 2,
    name: 'Día Libre',
  },
  {
    id: 3,
    name: 'Enfermedad',
  },
  {
    id: 4,
    name: 'Asuntos Propios',
  },
  {
    id: 5,
    name: 'Maternidad / Paternidad'
  },
  {
    id: 6,
    name: 'Festivo',
  },
  {
    id: 7,
    name: 'Festivo (local)'
  },
  {
    id: 8,
    name: 'Permiso por matrimonio'
  }
];

export const tipoVacacionesLibreToString = tipo => {
  const res = tipos.find(item => item.id === tipo);
  return res ? res.name : '';
};
