import React, {useState, useEffect} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from "../../services/authentication.service";
import {ActivityIndicator} from "react-native-web";
import logo from '../../../../common/assets/images/logo.png';



const Login = props => {

    const [loginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    useEffect(() => {
        // redirect to home if already logged in
        if (authenticationService.currentUserValue)
            props.history.push('/');
    }, []);

    return (
        <>
            <div className="login-wrapper">
                <img className="logo" src={logo} alt="logo"/>
                <Formik
                    initialValues={{
                        username: __DEV__ ? 'e1trabajador1@controldejornadalaboral.es' : '',
                        password: __DEV__ ? 'password' : ''
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required('Usuario es requerido'),
                        password: Yup.string().required('Contraseña es requerida')
                    })}
                    onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                        // const { from } = props.location.state || { from: { pathname: "/" } };
                        // props.history.push(from);
                        // setStatus();
                        authenticationService.login(username, password)
                            .then(user => {
                                const { from } = props.location.state || { from: { pathname: "/" } };
                                props.history.push(from);
                            })
                            .catch(error => {
                                setSubmitting(false);
                                setLoginError(true);
                                setLoginErrorMessage('Ha habido un problema al conectar con el servidor. Por favor, inténtalo de nuevo en unos minutos.');
                                console.log(error);

                                if(error === 'loginError')
                                    setLoginErrorMessage('El usuario o la contraseña es incorrecta')

                                if(error === 'pendientePago')
                                  setLoginErrorMessage('La empresa no está activa')

                                if (error === 'noPermiso')
                                    setLoginErrorMessage('Esta aplicación es sólo para trabajadores, tienes que acceder desde <a href="https://app.controldejornadalaboral.es/">aquí</a>')


                            });
                    }}
                >
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                {/*<label htmlFor="username">Usuario</label>*/}
                                <Field name="username" type="text" placeholder="E-mail" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                {/*<label htmlFor="password">Contraseña</label>*/}
                                <Field name="password" type="password" placeholder="Contraseña" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">

                                {isSubmitting ? (
                                    <ActivityIndicator/>
                                ) : (
                                    <>
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Iniciar Sesión</button>
                                        {loginError &&
                                        <p className="form-error" dangerouslySetInnerHTML={{__html: '* ' + loginErrorMessage}}></p>
                                        }
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="forgot-password">
                <a href="https://app.controldejornadalaboral.es/site/recover-password-step1">Recuperar contraseña</a>
            </div>
        </>
    )

}

export default Login ;
