//todo esto debería de vivir en common, pero como tiene local storage como dependencia, de momento lo dejo en web.
import {BehaviorSubject} from 'rxjs';
import axios from 'axios';
import {API_URL} from 'common/src/constants/Constants';
import {history} from "../helpers/history";
import {analyticsLogInUser} from "../hooks/withGaTracker";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userData')));


let llamado = false;
export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        const lastQueriedTime = localStorage.getItem('QueriedDataTime');
        const diff = (Date.now() - lastQueriedTime) / 1000;

        const currentUser = currentUserSubject.value

        if (diff > 10 || !lastQueriedTime) {

            if (!llamado) {
                llamado = true;
                refreshUserData();
            }
        }

        return currentUser;
    }
};

export const refreshUserData = (updateObservable = true) => {

    const at = localStorage.getItem('access_token');
    const bt = {
        headers: {Authorization: `Bearer ${at}`}
    };
    console.log('refreshing user data');
    axios.get(`${API_URL}user/view`, bt)
        .then(d => {

            if (d.data.data.rol === 1 || d.data.data.rol === 2) {
                console.log('refreshing user data ko')
            } else {
                localStorage.setItem('QueriedDataTime', Date.now())
                localStorage.setItem('userData', JSON.stringify(d.data.data));
                localStorage.setItem('empresaData', JSON.stringify(d.data.empresa));
                if (updateObservable && JSON.stringify(d.data.data) !== JSON.stringify(currentUserSubject.value))
                    currentUserSubject.next(d.data.data);
            }
        })
        .catch(e => {
            console.log(e)
        }).finally(() => llamado = false);
}

function login(username, password) {
    const data = {email: username, password};

    return new Promise((a, r) => {

        axios.post(`${API_URL}/user/login`, data)
            .then(d => {
                const {data} = d;

                if (data.status) {
                    const access_token = data.data.access_token;
                    localStorage.setItem('access_token', access_token);
                    const bt = {
                        headers: {Authorization: `Bearer ${access_token}`}
                    };
                    // Si el login no es correcto no se puede hacer la otra promesa
                    axios.get(`${API_URL}user/view`, bt)
                        .then(d => {

                            // rol administrador y encargados no pueden entrar
                            if (d.data.data.rol === 1 || d.data.data.rol === 2 ) {
                                r('noPermiso');
                            } else {
                                localStorage.setItem('QueriedDataTime', Date.now())
                                localStorage.setItem('userData', JSON.stringify(d.data.data));
                                localStorage.setItem('empresaData', JSON.stringify(d.data.empresa));
                                currentUserSubject.next(d.data.data);
                                analyticsLogInUser(d.data.data, d.data.empresa, true);
                                a(data);
                            }
                        })
                        .catch(e => {
                            r(e);
                            console.log(e)
                        });
                } else {
                    if (data.data.message === 'Empresa no activa')
                        r('pendientePago')
                    else
                        r('loginError');
                }

            })
            .catch(e => {
                r(e);
                console.log(e)
            });
    })

}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    currentUserSubject.next(null);
    history.push('/login');
}
