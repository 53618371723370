import {API_URL, EPS} from "../constants/Constants";
import axios from "axios";
import {refreshUserData} from "web/src/services/authentication.service";

/**
 * Devuelve una promesa que va a devolver a una cadena con el mensaje
 * o a false en caso de que no haya mensaje o de que este esté fuera de fechas
 * @param access_token string
 * @param pin string
 * @returns {Promise.Promise} string | bool
 */
const findByPin = async (access_token, pin) => {

    return new Promise(async (resolve, reject) => {

        console.log('calling: ' + API_URL + EPS.USER_FIND_BY_PIN)

        await refreshUserData(false)

        const config = {
            headers: {Authorization: 'Bearer ' + access_token},
        };

        axios.post(
            API_URL + EPS.USER_FIND_BY_PIN,
            { pin },
            config,
        )
            .then(data => {

                resolve(data.data)
            })
            .catch(error => reject(error))
    })

}

export default {
    findByPin
}
