import React, {useContext, useEffect, useRef, useState} from 'react';
import style from './pinpage.module.css';
import {API_URL, EPS} from "common/src/constants/Constants";
import Alert from 'common/src/components/Alert';
import axios from "axios";
import * as qs from 'qs';
import {AppContext} from "common/src/Context/AppContext";
import {authenticationService} from "../../services/authentication.service";

const PinFooter = () => {
    const [visible, setIsVisible] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [password, setPassword] = useState('')
    const inputRef = useRef()
    const {currentUser} = useContext(AppContext)

    useEffect(() => {
        if (visible)
            inputRef.current.focus()
    }, [visible])


    return <div className={style.footer} onDoubleClick={() => { setIsVisible(!visible)} }>
        {visible && <div className={style.footerRow}>
            <span>Para salir, introduce tu contraseña</span>

            <input
                type="password"
                ref={inputRef}
                onChange={e => setPassword(e.target.value)}
                value={password}
            />

            {password.length > 0 && <button onClick={async () => {
                setButtonDisabled(true);

                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                    },
                };

                const ep = API_URL + EPS.USER_LOGIN
                const {email} = currentUser
                console.log('Calling: ' + ep);
                axios
                    .post(ep, qs.stringify({email, password}), config)
                    .then(data => {
                        if (data?.data?.status === true)
                            authenticationService.logout()
                        else
                            Alert('Contraseña incorrecta', 'La contraseña introducida no es correcta. Recuerda que esta funcionalidad es para salir del usuario que gestiona los fichajes usando PIN, no del usuario que quiere iniciar o detener jornada.')
                        setButtonDisabled(false)
                    })
                    .catch(error => {
                        console.log(
                            'Error llamando a: ' + API_URL + EPS.USER_LOGIN,
                        );
                        console.log(error);
                        Alert.alert('Error', 'Error accediendo al servidor');
                    });
            }} className={style.buttonPassword}
            disabled={buttonDisabled}>
                {buttonDisabled ? 'Espere' : 'Salir'}
            </button>}
        </div>}
    </div>;
}

export default PinFooter;
