import React from 'react';
import withGaTracker from "../hooks/withGaTracker";
import {PrivateRoute} from "../components/PrivateRoute";
import PinPage from "../pages/Pin/PinPage";

const PinRoutes = props => <>
    <PrivateRoute path="/" exactly component={withGaTracker(PinPage)}  />
</>;

export default PinRoutes;
