import {API_URL, EPS} from "../../constants/Constants";
import axios from 'axios';
import {redirectRol, setUserData} from "mobile/models/User";
import {Alert, AsyncStorage} from "react-native";

/**
 *
 * Helper que devuelve una promesa con loading, working y workingStartTime
 *
 * @param {string} token - El token del usuario
 * @param {number|string|null} trabajadorId - El id del trabajador
 * @returns {Promise<unknown>}
 */
const comprobarEstadoTrabajando = (token, trabajadorId = null) => {
    return new Promise((resolve, reject) => {

        trabajadorId = trabajadorId !== null ? '?id=' + trabajadorId : '';
        const config = {
            headers: {Authorization: 'Bearer ' + token},
        };
        axios
            .post(API_URL + EPS.USER_TRABAJANDO + trabajadorId, {}, config)
            .then(data => {
                // loading, working, tiempo
                const res = [false, data.data.status, data.data.tiempo];
                resolve(res);
            })
            .catch(error => {
                console.log(
                    'Error en la petición a: ' +
                    API_URL +
                    EPS.USER_TRABAJANDO +
                    trabajadorId,
                );
                reject([false, null, null]);
            });
    });

};



export default {
    comprobarEstadoTrabajando
}
